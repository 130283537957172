export default {
  name: 'mRCScaleMixin',
  data: () => ({
    mRCScale: [
    // 0
      {
        question: 'Exam Date',
        data: null
      },
    // 1
      {
        question: 'Shoulder abduction',
        data: null
      },
    // 2
      {
        question: 'Elbow flexion',
        data: null
      },
    // 3
      {
        question: 'Wrist flexion',
        data: null,
      },
    // 4
      {
        question: 'Wrist extenstion',
        data: null,
      },
    // 5
      {
        question: 'Thumb abduction',
        data: null,
      },
    // 6
      {
        question: 'Hip flexion',
        data: null,
      },
    // 7
      {
        question: 'Knee extension',
        data: null,
      },
    // 8
      {
        question: 'Ankle dorsiflexion',
        data: null
      },
    // 9
      {
        question: 'Ankle eversion',
        data: null,
      },
    // 10
      {
        question: 'Ankle inversion',
        data: null,
      },
    // 11
      {
        question: 'Neck extension',
        data: null,
      },
    // 12
      {
        question: 'Shoulder external rotation',
        data: null,
      },
    // 13
      {
        question: 'Knee flexion',
        data: null,
      },
    // 14
      {
        question: 'Ankle plantar flexion',
        data: null,
      },
      {
        question: 'Hip extension',
        data: null,
      },
      {
        question: 'Hip abduction',
        data: null,
      },
      {
        question: 'Hip adduction',
        data: null,
      },
      {
        question: 'Elbow extension',
        data: null,
      },
      {
        question: 'Neck flexion',
        data: null,
      }
    ]
  }),
  methods: {
    assignMRCScale(data) {
      this.mRCScale[0].data = this.formatDateAbvMonth(data.dateOfMRCScale);
      this.mRCScale[1].data = 
        `Right: ${data.shoulderAbductionRight || '-'}, Left: ${data.shoulderAbductionLeft || '-'}`;
      this.mRCScale[2].data = 
        `Right: ${data.elbowFlexionRight || '-'}, Left: ${data.elbowFlexionLeft || '-'}`;
      this.mRCScale[3].data =
        `Right: ${data.wristFlexionRight|| '-'}, Left: ${data.wristFlexionLeft|| '-'}`;
      this.mRCScale[4].data = 
        `Right: ${data.wristExtensionRight || '-'}, Left: ${data.wristExtensionLeft || '-'}`;
      this.mRCScale[5].data =
        `Right: ${data.thumbAbductionRight || '-'}, Left: ${data.thumbAbductionLeft || '-'}`;
      this.mRCScale[6].data =
        `Right: ${data.hipFlexionRight || '-'}, Left: ${data.hipFlexionLeft || '-'}`;
      this.mRCScale[7].data =
        `Right: ${data.kneeExtensionRight || '-'}, Left: ${data.kneeExtensionLeft || '-'}`;
      this.mRCScale[8].data =
        `Right: ${data.ankleDorsiflexionRight || '-'}, Left: ${data.ankleDorsiflexionLeft || '-'}`;
      this.mRCScale[9].data =
        `Right: ${data.ankleEversionRight || '-'}, Left: ${data.ankleEversionLeft || '-'}`;
      this.mRCScale[10].data =
        `Right: ${data.ankleInversionRight || '-'}, Left: ${data.ankleInversionLeft || '-'}`;
      this.mRCScale[11].data = data.neckExtension || '-';
      this.mRCScale[12].data =
        `Right: ${data.shoulderExternalRotationRight || '-'}, Left: ${data.shoulderExternalRotationLeft || '-'}`;
      this.mRCScale[13].data =
        `Right: ${data.kneeFlexionRight || '-'}, Left: ${data.kneeFlexionLeft || '-'}`;
      this.mRCScale[14].data =
        `Right: ${data.hipExtensionRight || '-'}, Left: ${data.hipExtensionLeft || '-'}`;
      this.mRCScale[15].data =
        `Right: ${data.hipAbductionRight || '-'}, Left: ${data.hipAbductionLeft || '-'}`;
      this.mRCScale[16].data =
        `Right: ${data.hipAdductionRight || '-'}, Left: ${data.hipAdductionLeft || '-'}`;
      this.mRCScale[17].data =
        `Right: ${data.elbowExtensionRight || '-'}, Left: ${data.elbowExtensionLeft || '-'}`;
      this.mRCScale[18].data = data.neckFlexion || '-';
    }
  }
};
